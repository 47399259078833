import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-241faff0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "certification" }
const _hoisted_2 = { class: "certification__iframe" }
const _hoisted_3 = {
  key: 0,
  class: "certification__loading"
}
const _hoisted_4 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArrowLeftOutlined = _resolveComponent("ArrowLeftOutlined")!
  const _component_a_spin = _resolveComponent("a-spin")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: "certification__go-back",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.goBack && _ctx.goBack(...args)))
    }, [
      _createVNode(_component_ArrowLeftOutlined),
      _createTextVNode(" " + _toDisplayString(_ctx.t("CERTIFICATION.GO_BACK")), 1 /* TEXT */)
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_a_spin, {
              size: "large",
              spinning: _ctx.loading,
              tip: _ctx.t('LOADING')
            }, null, 8 /* PROPS */, ["spinning", "tip"])
          ]))
        : (_openBlock(), _createElementBlock("iframe", {
            key: 1,
            src: _ctx.url,
            allow: "camera;microphone"
          }, null, 8 /* PROPS */, _hoisted_4))
    ])
  ]))
}