
import { defineComponent, inject, onMounted, Ref, ref } from "vue";
import { useRouter } from "vue-router";
import { ArrowLeftOutlined } from "@ant-design/icons-vue";
import { useShowErrors } from "@hd2/common/src/composable/useShowErrors";
import { useI18n } from "vue-i18n";
import { AxiosStatic } from "axios";

export const CertificationComponent = defineComponent({
  components: {
    ArrowLeftOutlined,
  },
  setup() {
    const router = useRouter();
    const http = inject("http") as AxiosStatic;
    const url: Ref<string> = ref("");
    const { showErrors } = useShowErrors();
    const { t } = useI18n();

    const loading: Ref<boolean> = ref(true);

    const goBack = () => {
      router.back();
    };

    onMounted(async () => {
      try {
        url.value = await http
          .get(`v1/doctors/certificate`)
          .then((res) => res.data);
      } catch (err: any) {
        showErrors(err.response?.data);
      } finally {
        loading.value = false;
      }
    });

    return {
      loading,
      goBack,
      url,
      t,
    };
  },
});
export default CertificationComponent;
